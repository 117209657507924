import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { NewsletterForm } from '@components/NewsletterForm'
import LogoGreen from '../assets/images/RBC_Logo_Green.png'
import GiftGuideGif from '../assets/images/RBC_GiftGuide_Gif_102221.gif'

const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Logo = styled.img`
  width: 60px;
  margin-top: 63px;
  @media (max-width: 896px) {
    margin-top: 80px;
  }
`

const Gif = styled.img`
  width: 30%;
  margin-top: 27px;
  @media (max-width: 896px) {
    width: 80%;
    margin-top: 40px;
  }
`

const Text = styled.p`
  margin-top: 27px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  width: 35%;
  font-style: normal;
  font-weight: normal;
  @media (max-width: 896px) {
    width: 80%;
  }
`

const JoinText = styled(Text)`
  margin-top: 41px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.1em;
`

const JoinTheList = () => {
  return (
    <Box>
      <CenteredRow>
        <Logo src={LogoGreen} alt="logo" />
      </CenteredRow>
      <CenteredRow>
        <Gif src={GiftGuideGif} alt="gif" />
      </CenteredRow>
      <CenteredRow>
        <Text>
          With a calendar full of launches, reveals, and things we literally
          can't talk about, this would be a good time to sign up for our email
          list.
        </Text>
      </CenteredRow>
      <CenteredRow>
        <JoinText>JOIN THE LIST</JoinText>
      </CenteredRow>
      <CenteredRow>
        <NewsletterForm />
      </CenteredRow>
    </Box>
  )
}

export default JoinTheList
